import { useEffect } from 'react';
import { useState } from 'react';
import styles from './AtaInput.module.css';

export const AtaInput = ({ name, value, ataId, min, max, onChangeHandler }) => {
    const [formName, setFormName] = useState(name);
    const [formValue, setFormValue] = useState(value);
    // const [style, setStyle] = useState(styles.ataInput)

    // useEffect(() => {
    //     if(formValue === )
    // },[])

    let style = `${styles.ataInput} ${formValue == 0 ? styles.zero : ''} ${Number(formValue) > max || Number(formValue) < min ? styles.error : null} ${
        styles[name]
    }`;

    const change = (e) => {
        setFormName((state) => e.target.name);
        setFormValue((state) => e.target.value);
        onChangeHandler(ataId, e.target.name, e.target.value);
    };

    const increase = () => {
        if (formValue < max) {
            setFormValue((state) => {
                onChangeHandler(ataId, name, state + 1);

                return state + 1;
            });
        }
    };
    const decrease = () => {
        if (formValue > min) {
            setFormValue((state) => {
                onChangeHandler(ataId, name, state - 1);
        
                return state - 1;
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.availableQuestions}>[tp]</div>
            <div className={styles.minus} onClick={decrease}>
                <i className="fa-solid fa-minus"></i>
            </div>
            {/* <label htmlFor="formName"> */}
            <input className={style} type="number" name={formName} min={min} max={max} value={formValue} onChange={change} />
            {/* </label> */}
            <div className={styles.plus} onClick={increase}>
                <i className="fa-solid fa-plus"></i>
            </div>
            <span className={styles.availableQuestions}>{max}</span>
        </div>
    );
};
