import { Link } from 'react-router-dom';
import styles from './NewItemBtn.module.css';

export const NewItemBtn = ({ children, onClick, oldForm }) => {
    const addHandler = () => {
        onClick();
    };

    return (
        <div className={styles.right}>
            {oldForm ? (
                <button className={styles.addBtn} onClick={addHandler}>
                    {/* <strong>+</strong> */}
                    <strong>{children}</strong>
                </button>
            ) : (
                <Link to={'/questions/new'} className={styles.addBtn}>
                    {children}
                </Link>
            )}
        </div>
    );
};
